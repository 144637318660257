import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { getMethod, uploadPostMethod } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router";
import Loader from "../layout/loader/Loader";

const Providers = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [providersCode, setProvidersCode] = useState([]);
  const [refr, setRefr] = useState(true);
  const [editProvider, setEditProvider] = useState(false);
  const [createProvider, setCreateProvider] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [providerImage, setProviderImage] = useState("");
  const [providerCode, setProviderCode] = useState("");
  const [providerNameValidate, setProviderNameValidate] = useState("");
  const [providerImageValidate, setProviderImageValidate] = useState("");
  const [providerCodeValidate, setProviderCodeValidate] = useState("");
  const [providerEditid, setProviderEditid] = useState("");
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      let res = await getMethod(`/providers`);
      if (res) {
        setLoading(false);
        if (res.status === "success") {
          if (res.data) {
            let temp = [];
            setData([]);
            if (res.data.length > 0) {
              res.data.map((el) => {
                el.status === "active"
                  ? (el.status = (
                      <i className="fa fa-circle font-success f-12" />
                    ))
                  : (el.status = (
                      <i className="fa fa-circle font-danger f-12" />
                    ));
                return temp.push(el);
              });
            }
            setData(temp);
          }
        }
      } else {
        setLoading(false);
      }
      let resProviderCode = await getMethod("/provider/codes");
      if (resProviderCode) {
        setLoading(false);
        if (resProviderCode.status === "success") {
          if (resProviderCode.data) {
            setProvidersCode(resProviderCode.data);
          }
        }
      } else {
        setLoading(false);
      }
    })();
  }, [refr]);

  const editOpenModal = () => {
    setEditProvider(!editProvider);
    setProviderName("");
    setProviderCode("");
    setProviderImage("");
    setProviderNameValidate("");
    setProviderCodeValidate("");
    setProviderImageValidate("");
    setStatus("");
  };

  const createOpenModal = () => {
    setCreateProvider(!createProvider);
    setProviderName("");
    setProviderCode("");
    setProviderImage("");
    setProviderNameValidate("");
    setProviderCodeValidate("");
    setProviderImageValidate("");
    setStatus("");
  };

  const statusHandler = (e) => {
    setStatus(e.target.value);
  };

  const providerCodeHandler = (e) => {
    setProviderCode(e.target.value);
    setProviderCodeValidate("");
  };

  const createSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", providerCode);
      formData.append("full_name", providerName);
      formData.append("photo", providerImage);

      const token = JSON.parse(localStorage.getItem("admin_auth"));
      const response = await axios.request(
        await uploadPostMethod("/providers", formData, token.token)
      );
      if (response.data.status === "success") {
        setLoading(false);
        setCreateProvider(false);
        setRefr(!refr);
        setProviderName("");
        setProviderImage("");
        setProviderCode("");
      }
    } catch (error) {
      if (error.response) {
        setLoading(false);
        if (error.response.status === 403) {
          setCreateProvider(false);
          SweetAlert.fire({
            icon: "error",
            width: 300,
            title: <p>Fail</p>,
            text: error.response.data.message,
          });
        }
        if (error.response.data) {
          if (error.response.data.message === "Unauthenticated.") {
            localStorage.removeItem("admin_auth");
            navigate("/admin/login");
          }
        }
        if (error.response.data.message) {
          if (error.response.data.message.name) {
            setProviderCodeValidate(error.response.data.message.name);
          }
          if (error.response.data.message.full_name) {
            setProviderNameValidate(error.response.data.message.full_name[0]);
          }
          if (error.response.data.message.photo) {
            setProviderImageValidate(error.response.data.message.photo[0]);
          }
        }
      }
    }
  };
  const editSubmitHandler = async () => {
    setLoading(true);
    if (providerEditid) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("name", providerCode);
        formData.append("full_name", providerName);
        formData.append("photo", providerImage);
        formData.append("status", status);
        const token = JSON.parse(localStorage.getItem("admin_auth"));
        const response = await axios.request(
          await uploadPostMethod(
            `/providers/${providerEditid}/update`,
            formData,
            token.token
          )
        );
        if (response.data.status === "success") {
          setLoading(false);
          setEditProvider(false);
          setRefr(!refr);
          setProviderName("");
          setProviderImage("");
          setProviderCode("");
        }
      } catch (error) {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 403) {
            setEditProvider(false);
            SweetAlert.fire({
              icon: "error",
              width: 300,
              title: <p>Fail</p>,
              text: error.response.data.message,
            });
          }
          if (error.response.data) {
            if (error.response.data.message === "Unauthenticated.") {
              localStorage.removeItem("admin_auth");
              navigate("/admin/login");
            }
          }
          if (error.response.data.message) {
            if (error.response.data.message.name) {
              setProviderCodeValidate(error.response.data.message.name);
            }
            if (error.response.data.message.full_name) {
              setProviderNameValidate(error.response.data.message.full_name[0]);
            }
            if (error.response.data.message.photo) {
              setProviderImageValidate(error.response.data.message.photo[0]);
            }
          }
        }
      }
    }
  };

  const chunk = (num) => {
    let str = num.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }
    return str.join(".");
  };
  return (
    <>
      <Layout>
        <Breadcrumbs title="Providers" />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-between">
                  <div>
                    {data
                      .filter((el1) => el1.name === "PR" || el1.name === "JK")
                      .map((el) => (
                        <div key={el.id} style={{ fontSize: "16px" }}>
                          {el.name === "PR"
                            ? "New Providers : "
                            : "Initial Providers : "}{" "}
                          {el?.provider_balance?.toLocaleString("en-us")}
                        </div>
                      ))}
                  </div>
                  <Button color="primary" onClick={createOpenModal}>
                    Create
                  </Button>
                </CardHeader>
                <CardBody className="position-relative">
                  <div className="table-responsive table-scroll">
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>No.</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Photo</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Provider</b>
                          </th>
                          {/* <th className='text-center text-white' scope="col"><b>Balance</b></th>
                                                    <th className='text-center text-white' scope="col"><b>MMK</b></th> */}
                          <th className="text-center text-white" scope="col">
                            <b>Status</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Action</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 &&
                          data.map((el, i) => (
                            <tr key={i} className="align-middle">
                              <th className="text-center" scope="row">{`${
                                i + 1
                              }.`}</th>
                              <td className="text-center">
                                <img
                                  src={el.photo}
                                  alt="payment"
                                  style={{ width: "60px", borderRadius: "5px" }}
                                />
                              </td>
                              <td className="text-center">
                                {el.full_name && el.full_name}
                              </td>
                              {/* <td style={{ textAlign: 'right' }}>{el.provider_unit ? chunk(el.provider_unit): 0}</td>
                                                        <td style={{ textAlign: 'right' }}>{el.provider_balance ? chunk(el.provider_balance): 0}</td> */}
                              <td className="text-center">{el.status}</td>
                              <td className="text-center">
                                <div>
                                  <span
                                    onClick={() => {
                                      editOpenModal();
                                      setProviderEditid(el.id);
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      style={{
                                        width: 35,
                                        fontSize: 18,
                                        padding: 11,
                                        color: "rgb(40, 167, 69)",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                  {loading && <Loader />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={createProvider} toggle={createOpenModal} centered>
          <Form onSubmit={createSubmitHandler}>
            <ModalHeader toggle={createOpenModal}>Create Provider</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Provider Code</Label>
                <Input
                  type="select"
                  name="select"
                  className="form-control"
                  onChange={providerCodeHandler}
                >
                  <option value="">Choose</option>
                  {providersCode.length > 0 &&
                    providersCode.map((el, i) => (
                      <option key={i} value={el.provider_code}>
                        {el.provider_name}
                      </option>
                    ))}
                </Input>
                {providerCodeValidate && (
                  <div className="invalid-feedback d-block">
                    {providerCodeValidate}
                  </div>
                )}
              </FormGroup>

              <FormGroup>
                <Label htmlFor="">Provider Name</Label>
                <Input
                  className="form-control"
                  type="text"
                  defaultValue={providerName}
                  placeholder="Provider Name"
                  onChange={(e) => {
                    setProviderName(e.target.value);
                    setProviderNameValidate("");
                  }}
                />
                {providerNameValidate && (
                  <div className="invalid-feedback d-block">
                    {providerNameValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Photo</Label>
                <Input
                  className="form-control"
                  type="file"
                  placeholder=""
                  onChange={(e) => {
                    setProviderImage(e.target.files[0]);
                    setProviderImageValidate("");
                  }}
                />
                {providerImageValidate && (
                  <div className="invalid-feedback d-block">
                    {providerImageValidate}
                  </div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={createOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
      {
        <Modal isOpen={editProvider} toggle={editOpenModal} centered>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              editSubmitHandler();
            }}
          >
            <ModalHeader toggle={editOpenModal}>Edit Provider</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Provider Code</Label>
                <Input
                  type="select"
                  name="select"
                  className="form-control"
                  onChange={providerCodeHandler}
                >
                  <option value="">Choose</option>
                  {providersCode.length > 0 &&
                    providersCode.map((el, i) => (
                      <option key={i} value={el.provider_code}>
                        {el.provider_name}
                      </option>
                    ))}
                </Input>
                {providerCodeValidate && (
                  <div className="invalid-feedback d-block">
                    {providerCodeValidate}
                  </div>
                )}
              </FormGroup>

              <FormGroup>
                <Label htmlFor="">Provider Name</Label>
                <Input
                  className="form-control"
                  type="text"
                  defaultValue={providerName}
                  placeholder="Provider Name"
                  onChange={(e) => {
                    setProviderName(e.target.value);
                    setProviderNameValidate("");
                  }}
                />
                {providerNameValidate && (
                  <div className="invalid-feedback d-block">
                    {providerNameValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Photo</Label>
                <Input
                  className="form-control"
                  type="file"
                  placeholder=""
                  onChange={(e) => {
                    setProviderImage(e.target.files[0]);
                    setProviderImageValidate("");
                  }}
                />
                {providerImageValidate && (
                  <div className="invalid-feedback d-block">
                    {providerImageValidate}
                  </div>
                )}
              </FormGroup>
              <Row>
                <Col>
                  <div className="mb-0">
                    <Label>Payment Status</Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control form-control-sm digits"
                      onChange={statusHandler}
                    >
                      <option value="">Choose</option>
                      <option value="active">Active</option>
                      <option value="disable">Disabled</option>
                    </Input>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={editOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  );
};

export default Providers;
